<template>
  <div class="container" ref="wrapper">
    <van-search
      shape="round"
      v-model="pagePara.filterData.title"
      @search="onSearch"
      placeholder="请输入搜索关键词"
    />
    <van-dropdown-menu>
      <van-dropdown-item title="分类" ref="item">
        <van-tree-select
          :height="450"
          :items="ccitems"
          :active-id.sync="activeId"
          :main-active-index.sync="activeIndex"
          @click-item="onitemclick"
          @click-nav="onitemnavclick"
        />
      </van-dropdown-item>
      <van-dropdown-item
        :options="option2"
        v-model="pagePara.sorterType"
        @change="changeSorterType"
      />
      <van-dropdown-item
        :options="option3"
        v-model="pagePara.filterData.status"
        @change="changestatus"
      />
    </van-dropdown-menu>
    <!-- <van-row>
      <van-col span="8">
        <van-dropdown-menu>
          <van-dropdown-item title="分类" ref="item">
            <van-tree-select
              :height="450"
              :items="ccitems"
              :active-id.sync="activeId"
              :main-active-index.sync="activeIndex"
              @click-item="onitemclick"
              @click-nav="onitemnavclick"
            />
          </van-dropdown-item>
        </van-dropdown-menu>
      </van-col>
      <van-col span="16">
        <van-search
          v-model="pagePara.filterData.title"
          @search="onSearch"
          placeholder="请输入搜索关键词"
        />
      </van-col>
    </van-row> -->
    <!-- <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="pagePara.sorterType == 0 ? '#9d1d22' : ''"
          @click="changeSorterType(0)"
          >默认</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="pagePara.sorterType == 3 ? '#9d1d22' : ''"
          @click="changeSorterType(3)"
          >最热</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="pagePara.sorterType == 1 ? '#9d1d22' : ''"
          @click="changeSorterType(1)"
          >最新</van-tag
        >
      </van-col>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="pagePara.sorterType == 2 ? '#9d1d22' : ''"
          @click="changeSorterType(2)"
          >最早</van-tag
        >
      </van-col>
    </van-cell>
    <van-cell>
      <van-col span="4">
        <van-tag
          round
          size="large"
          :color="pagePara.filterData.status == 0 ? '#9d1d22' : ''"
          @click="changestatus(0)"
          >全部</van-tag
        >
      </van-col>
      <van-col span="5">
        <van-tag
          round
          size="large"
          :color="pagePara.filterData.status == 1 ? '#9d1d22' : ''"
          @click="changestatus(1)"
          >预约中</van-tag
        >
      </van-col>
      <van-col span="5">
        <van-tag
          round
          @click="changestatus(2)"
          size="large"
          :color="pagePara.filterData.status == 2 ? '#9d1d22' : ''"
        >
          已开课
        </van-tag>
      </van-col>
      <van-col span="5">
        <van-tag
          round
          @click="changestatus(4)"
          size="large"
          :color="pagePara.filterData.status == 4 ? '#9d1d22' : ''"
        >
          筹备中
        </van-tag>
      </van-col>
    </van-cell> -->
    <van-pull-refresh v-model="refreshing" @refresh="onRefresh">
      <van-list
        v-model="upLoading"
        :finished="finished"
        @load="onLoad"
        finished-text="没有更多了"
      >
        <van-cell-group>
          <!-- art_id有可能是大数字 超过一定长度会转成 BigNumber  要toString一下 -->

          <van-cell
            @click="toDetailWithScroll(item)"
            v-for="item in articles"
            :key="item.id.toString()"
          >
            <van-col span="8"
              ><van-image
                lazy-load
                class="w100"
                fit="contain"
                style="width: 110px; height: 120px"
                :src="item.imageUrl"
              />
            </van-col>
            <van-col span="16" align="bottom">
              <div style="margin-left: 20px">
                <div
                  style="display: table-cell; vertical-align: middle"
                  class="zntitle van-multi-ellipsis--l3"
                >
                  <h3 style="display: inline-block">{{ item.title }}</h3>
                </div>

                <div style="width: 100%; color: #696969">
                  讲师： {{ item.teacherUserName }}
                </div>
                <div style="width: 100%; color: #696969">
                  开始时间： {{ item.startTime }}
                </div>

                <div style="float: right; margin-right: 13px">
                  浏览{{ item.readCount }}人次
                </div>

                <div style="width: 100%">
                  <van-tag
                    v-if="item.status == 1"
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >预约中
                  </van-tag>
                  <van-tag
                    v-if="item.status == 2"
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >已开课
                  </van-tag>
                  <van-tag
                    v-if="item.status == 3"
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >已取消
                  </van-tag>

                  <van-tag
                    v-if="item.status == 4"
                    size="large"
                    color="rgb(255 242 219)"
                    text-color="#9d1d22"
                    >筹备中
                  </van-tag>
                  &nbsp;&nbsp;
                  <span
                    v-if="
                      item.hasSignUp != undefined &&
                      item.hasSignUp &&
                      (item.status == 1 || item.status == 4)
                    "
                    style="color: #c7861b; font-weight: bold"
                    >已报名
                  </span>

                  <!-- &nbsp;&nbsp;
               <span
                  v-if="item.hasSignIn!=undefined && item.hasSignIn"
                 style=" color: #c7861b; font-weight: bold"
                  >已签到
                </span> -->
                  &nbsp;&nbsp;
                  <van-tag
                    v-if="
                      item.audioUrl != undefined && item.audioUrl.length > 5
                    "
                    mark
                    plain
                    color="#9d1d22"
                    >有音频
                  </van-tag>
                  &nbsp;&nbsp;
                  <van-tag
                    v-if="
                      item.videoUrl != undefined && item.videoUrl.length > 5
                    "
                    mark
                    plain
                    color="#9d1d22"
                    >有视频
                  </van-tag>
                  <div
                    v-if="item.browsingTime != undefined"
                    style="float: left; color: #c7861b; font-weight: bold"
                  >
                    {{
                      item.browsingTime != undefined
                        ? getbrowsingTimeStr(item)
                        : ""
                    }}
                  </div>
                </div>
              </div>
            </van-col>
          </van-cell>
        </van-cell-group>
      </van-list>
    </van-pull-refresh>
  </div>
</template>

<script>
import {
  getCourseOfflineListBeforeSingUp,
  getCourseOfflineList,
  getListSignUp,
  getListSignIn,
  getListNotComment,
  getListNotCommentForSchool,
  getTeacher,
} from "@/api/courseoffline";
import { getUserName } from "@/api/user";
import { getClassWithChildren } from "@/api/school";

export default {
  props: {
    Type: {
      type: Number,
      default: 0, //1预约前列表  2我的预约列表 3我的签到列表  4我的授课列表 5 待评价
    },
    toDetail: Function,
  },

  data() {
    return {
      scroll:0,
      ccitems: [],
      activeId: 1,
      activeIndex: 0,

      refreshing: false,
      keyword: "",
      upLoading: false,
      finished: false,
      articles: [],
      pagePara: {
        current: 1,
        pageSize: 10,
        sorterType: 1,
        fromType: 1,
        filterData: {
          title: "",
          status: 2,
          schoolClass: 0,
          schoolClass2: 0,
        },
      },
      value2: 1,
      value3: 2,
      option2: [
        { text: "默认", value: 0 },
        { text: "最热", value: 3 },
        { text: "最新", value: 1 },
        { text: "最早", value: 2 },
      ],
      option3: [
        { text: "全部", value: 0 },
        { text: "预约中", value: 1 },
        { text: "已开课", value: 2 },
        { text: "筹备中", value: 4 },
      ],
    };
  },
  components: {},
  created() {
    this.initData();
  },
  watch: {
    // $route(to, from) {
    //   //监听路由是否变化
    //   if (to.query != from.query) {
    //     this.initData();
    //     this.onSearch();
    //   }
    // },
  },
  activated() {
    try {
      this.$refs.wrapper.scrollTop = this.scroll;
    } catch (error) {
      console.log(error);
    }
  },
  mounted() {},

  methods: {
    onRefresh() {
      this.onSearch();
      this.refreshing = false;
    },
    async initData() {
      if (this.$route.query.uid != undefined) {
        let nameReuslt = await getUserName(this.$route.query.uid);
        this.pagePara.filterData.title = nameReuslt.data.data;
      }
      if (this.$route.query.tid != undefined) {
        let nameReuslt = await getTeacher(this.$route.query.tid);
        this.pagePara.filterData.title = nameReuslt.data.data.userName;
      }
      if (this.$route.query.status != undefined) {
        this.pagePara.filterData.status= parseInt(this.$route.query.status);
      }
      //sorterType
      if (this.$route.query.sorterType != undefined) {
        this.pagePara.sorterType=parseInt(this.$route.query.sorterType);
      }

      if (this.$route.query.id != undefined) {
        this.pagePara.filterData.schoolClass = this.$route.query.id;
      } else {
        this.pagePara.filterData.schoolClass = 0;
      }
      if (this.$route.query.id2 != undefined) {
        this.pagePara.filterData.schoolClass2 = this.$route.query.id2;
      } else {
        this.pagePara.filterData.schoolClass2 = 0;
      }

      let cresult = await getClassWithChildren({
        fromType: 1,
        filterData: { parentID: 1 },
      });
      this.ccitems = [];
      cresult.data.data.forEach((citem, cindex) => {
        let chilrenitem = [];
        //根据参数设置选择的分类
        if (citem.id == this.pagePara.filterData.schoolClass) {
          this.activeIndex = cindex;
        }
        citem.children.forEach((chitem) => {
          //根据参数设置选择的分类
          if (chitem.id == this.pagePara.filterData.schoolClass2) {
            this.activeIndex = cindex;
            this.activeId = chitem.id;
          }
          chilrenitem.push({
            text: chitem.name + "(" + chitem.count + "条)",
            id: chitem.id,
          });
        });
        this.ccitems.push({
          text: citem.name + "(" + citem.count + "条)",
          id: citem.id,
          children: chilrenitem,
        });
      });
    },
    changeSorterType() {
      // if (this.pagePara.sorterType != typeid) {
      //   this.pagePara.sorterType = typeid;
        
      // }
      this.onSearch();
    },

    changestatus() {
      // if (this.pagePara.filterData.status != status) {
      //   this.pagePara.filterData.status = status;
        
      // }
      this.onSearch();
    },

    toDetailWithScroll(item){

      let wrapperScrollTop = this.$refs.wrapper.scrollTop;
      //存储 scrollTop 的值
      this.scroll = wrapperScrollTop;

      this.toDetail(item);
    },

    onitemclick(data) {
      this.$refs.item.toggle();
      if (data.text.indexOf("全部") >= 0) {
        //全部
        this.pagePara.filterData.schoolClass = data.id;
        this.pagePara.filterData.schoolClass2 = 0;
      } else {
        this.pagePara.filterData.schoolClass = 0;
        this.pagePara.filterData.schoolClass2 = data.id;
      }
      this.onSearch();
      //this.$router.push({path:'/course/list?cid'+data.id})
    },
    onitemnavclick(index) {
      if (this.ccitems[index].children.length == 0) {
        this.$refs.item.toggle();

        this.pagePara.filterData.schoolClass = this.ccitems[index].id;
        this.pagePara.filterData.schoolClass2 = 0;
        this.onSearch();

        //this.$router.push({path:'/course/list?cid='+this.ccitems[index].id})
      }
    },

    onSearch() {
      this.articles = [];
      this.pagePara.current = 1;
      this.pagePara.pageSize = 10;
      this.onLoad();
    },

    async onLoad() {
      this.upLoading = true;
      if (this.$route.query.uid != undefined) {
        let nameReuslt = await getUserName(this.$route.query.uid);
        this.pagePara.filterData.title = nameReuslt.data.data;
      }
      if (this.$route.query.tid != undefined) {
        let nameReuslt = await getTeacher(this.$route.query.tid);
        this.pagePara.filterData.title = nameReuslt.data.data.userName;
      }

      let aresult;
      if (this.Type == 1) {
        aresult = await getCourseOfflineListBeforeSingUp({ ...this.pagePara });
      } else if (this.Type == 2) {
        aresult = await getListSignUp({ ...this.pagePara });
      } else if (this.Type == 3) {
        aresult = await getListSignIn({ ...this.pagePara });
      } else if (this.Type == 4) {
        aresult = await getCourseOfflineList({ ...this.pagePara });
      } else if (this.Type == 5) {
        aresult = await getListNotComment({ ...this.pagePara });
      } else if (this.Type == 6) {
        aresult = await getListNotCommentForSchool({ ...this.pagePara });
      }
      this.articles.push(...aresult.data.data);
      this.upLoading = false;
      if (this.pagePara.current * this.pagePara.pageSize < aresult.data.total) {
        this.pagePara.current++;
        this.finished = false;
      } else {
        this.finished = true;
      }
    },
    getbrowsingTimeStr(item) {
      if(item.browsingTime.part&&item.browsingTime.part!="")
      {
        return "已学"+item.browsingTime.part+"章节";
      }
      if (item.browsingTime.percent >= 0.01) {
        if (item.browsingTime.percent == 1) {
          return "已学完";
        } else {
          return "已学" + (item.browsingTime.percent * 100).toFixed(0) + "%";
        }
      } else {
        return ""; //"未学习"
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  height: 100%;
  overflow-y: auto;
  padding-bottom: 45px;
  box-sizing: border-box;
}
</style>
